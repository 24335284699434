















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import CardLink from '@/components/Cards/CardLink.vue'
import { CardLinkObj } from '@/contracts/Cards'

export default defineComponent({
  name: 'CardLinkList',
  components: {
    CardLink,
  },
  props: {
    list: {
      type: Array as PropType<CardLinkObj[]>,
      default: () => [],
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
})
