
















import { defineComponent } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'
import { CardLinkObj } from '@/contracts/Cards'
import { defineProps } from '@/helpers/defineProps'

export default defineComponent({
  name: 'CardLink',
  components: {
    Link,
  },
  props: defineProps<CardLinkObj>({
    title: {
      default: '',
    },
    url: {
      default: '',
    },
  }),
  setup() {},
})
