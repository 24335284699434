




































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { take } from 'lodash'
import { SectionCore } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import CardLinkList from '@/components/CardLists/BlockLinksList.vue'

@Component
class SectionLinksList extends mixins(SectionCore) {
  @Prop({ default: () => [] }) readonly cardsLinkData:
    | ReadonlyArray<any>
    | undefined

  @Prop() readonly illustration: boolean = true
  @Prop() readonly alternateView: boolean = false
  @Prop() readonly visibleLinksCount: any = null
}

export default defineComponent({
  name: 'SectionLinksList',
  components: {
    SectionBasic,
    CardLinkList,
  },
  props: SectionLinksList.options.props,
  setup(props) {
    const isListCollapsed = ref(true)
    const preparedLinks = computed(() => {
      if (!props.visibleLinksCount) return props.cardsLinkData
      return isListCollapsed.value
        ? take(props.cardsLinkData, props.visibleLinksCount)
        : props.cardsLinkData
    })
    const toggleCollapseList = () => {
      isListCollapsed.value = !isListCollapsed.value
    }
    return {
      isListCollapsed,
      preparedLinks,
      toggleCollapseList,
    }
  },
})
